
import { VueDraggableNext } from 'vue-draggable-next'
import { defineComponent } from 'vue'
export default defineComponent({
  components: {
    draggable: VueDraggableNext
  },
  props: {
    headerText: {
      type: String,
      default: 'header'
    },
    list: {
      type: Array,
      default: () => {
        return []
      }
    },
    options: {
      type: Object,
      default: () => {
        return null
      }
    }

  },
  setup(props) {
    console.log(props)
  }
})

