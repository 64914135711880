
import DraggableKanban from '@/components/draggable-kanban/Index.vue'
import { defineComponent, reactive, toRefs } from 'vue'
export default defineComponent({
  components: {
    DraggableKanban
  },
  setup() {
    const dataMap = reactive({
      group: 'mission',
      list1: [
        { name: 'Mission', id: 1 },
        { name: 'Mission', id: 2 },
        { name: 'Mission', id: 3 },
        { name: 'Mission', id: 4 }
      ],
      list2: [
        { name: 'Mission', id: 5 },
        { name: 'Mission', id: 6 },
        { name: 'Mission', id: 7 }
      ],
      list3: [
        { name: 'Mission', id: 8 },
        { name: 'Mission', id: 9 },
        { name: 'Mission', id: 10 }
      ]
    })
    return { ...toRefs(dataMap) }
  }
})
